import { pageStyles } from "../../styles/style";
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import "../../styles/global.css";
import iso from "../../images/certifi/MANUTEPUL-ISO9001.webp";
import cer from "../../images/certifi/TRANS-CER.webp";

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul | ISO 9001</title>
        <meta
          name="keywords"
          content="impresa di pulizie milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di disinfestazioni"
        />
      </Helmet>
      <Navbar />
      <DownbarS />
      <Banner
        pageName="Certificazioni "
        prepageName="ISO"
        postpageName="9001"
      />
      <section id="service" className="service-section pt-75 pb-100">
        <div className="container">
          <div className="row">
            <div class="elementor-container elementor-column-gap-default">
              <div data-id="613b2d4" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="certified-div">
                      <h1 class="elementor-heading-title elementor-size-default">
                        Certificazione{" "}
                        <span>ISO 9001</span> per la
                        Qualità
                      </h1>
                  </div>
                  <div
                    class="elementor-element elementor-element-65fe5e5 elementor-widget elementor-widget-text-editor"
                    data-id="65fe5e5"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="text-certified-div">
                      <p>
                        Noi di Manutenpul abbiamo fatto una scelta di qualità che
                        riguarda sia la professionalità dei dipendenti, sia
                        l’alta qualità dei prodotti, delle attrezzature e dei
                        macchinari, per questo abbiamo messo in pratica
                        metodologie che ci hanno permesso di ottenere
                        <strong>
                          l’attestato di qualità con la
                          <em>Certificazione ISO 9001</em>
                        </strong>
                        &nbsp;rilasciata dall’Ente certificatore “Accredia” al n
                        1382 sin
                        <strong>dal 2001 e rinnovato di anno in anno</strong>.
                      </p>
                      <p>
                        Questa norma internazionale, che ora è chiamata
                        9001:2015 per evidenziare l’anno in cui è stata
                        aggiornata, è stata definita dall’International
                        Organization for Standardization (ISO)&nbsp; e prende in
                        esame i
                        <strong>Sistemi di Gestione per la Qualità</strong>{" "}
                        (SGQ).
                      </p>
                      <p>
                        Per ottenere la certificazione bisogna realizzare
                        documenti con le descrizione dei processi e delle
                        politiche aziendali che devono essere coerenti con le
                        linee guida della norma. Rendere esplicite le regole e i
                        metodi di lavoro, inoltre, garantisce
                        <strong>
                          trasparenza sia nei confronti dei dipendenti, sia nei
                          confronti dei clienti
                        </strong>
                        .
                      </p>
                      <p>
                        Abbiamo ottenuto questo attestato perché siamo stati
                        sempre attenti ad usare dei prodotti adatti a risolvere
                        ogni problema di pulizia, a non danneggiare gli ambienti
                        da pulire e a
                        <strong>
                          non provocare inconvenienti alle persone
                        </strong>{" "}
                        che frequentano gli ambienti stessi.
                      </p>
                      <p>
                        Chiunque ha usufruito dei nostri servizi può affermare
                        che l’
                        <strong>
                          <a href="https://www.manutenpul.it/">
                            impresa di pulizie
                          </a>
                        </strong>
                        &nbsp;Manutenpul Pulizie Milano utilizza attrezzature
                        all’avanguardia e macchinari silenziosi e che non
                        inquinano.
                      </p>
                      <p>
                        La&nbsp;ISO 9001&nbsp;si rivolge a qualsiasi tipologia
                        di&nbsp;organizzazione pubblica o privata, di qualsiasi
                        settore e dimensione, manifatturiera o di&nbsp;
                        <u>servizi</u>.
                      </p>
                      <p>
                        È lo standard di riferimento internazionalmente
                        riconosciuto per la&nbsp;gestione della qualità&nbsp;di
                        qualsiasi organizzazione che intenda rispondere
                        contemporaneamente:
                      </p>
                      <ul>
                        <li>&#x2022;
                          All’esigenza dell’aumento dell’efficacia ed efficienza
                          dei processi interni – quale strumento di
                          organizzazione per raggiungere i propri obiettivi;
                        </li>
                        <li>&#x2022;
                          Alla crescente competitività nei mercati attraverso il
                          miglioramento della soddisfazione e della
                          fidelizzazione dei clienti.
                        </li>
                      </ul>
                      <p>
                        Scopo primario dell’ISO 9001 è il perseguimento
                        della&nbsp;
                        <strong>soddisfazione del proprio cliente</strong>
                        &nbsp;in merito ai prodotti e servizi forniti, nonché il
                        continuo&nbsp;miglioramento&nbsp;delle prestazioni
                        aziendali,&nbsp;permettendo all’azienda certificata
                        di&nbsp;
                        <strong>
                          assicurare ai propri clienti il mantenimento e
                          il&nbsp;miglioramento nel tempo della qualità dei
                          propri beni e servizi.
                        </strong>
                      </p>
                      <p>
                        Da questo punto di vista il modello ISO 9001 è
                        uno&nbsp;strumento strategico&nbsp;in quanto mirato a:
                      </p>
                      <ul>
                        <li>&#x2022; Valutazione del contesto e parti interessate;</li>
                        <li>&#x2022; 
                          Analisi di rischi ed opportunità come base per
                          definire opportune azioni;
                        </li>
                        <li>&#x2022; Controllo dei costi;</li>
                        <li>&#x2022; Aumento della produttività;</li>
                        <li>&#x2022; Riduzione degli sprechi.</li>
                      </ul>
                      <p>
                        Tutto questo si traduce nella&nbsp;riduzione del rischio
                        di non rispettare quanto promesso&nbsp;ai clienti e
                        nella capacità di tenere&nbsp;sotto controllo i
                        processi&nbsp;tramite la misurazione delle prestazioni e
                        l’individuazione di adeguati&nbsp;indicatori.
                      </p>
                      <p>
                        Inoltre viene spesso
                        <strong>
                          richiesta dai&nbsp;bandi di gara pubblici
                        </strong>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
                <div class="certified-div">
                    <img class="img-cert" alt="iso 9001" src={iso} />
                </div>
            </div>
            <div className="col-12">
                <div class="certified-div">
                    <img class="img-cert-company" alt="cer company" src={cer} />
                </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default IndexPage;
